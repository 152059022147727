import { useEffect, memo, useState } from "react";
import PropTypes from "prop-types";
import LocalStorage from "../defined/localStorage";

const ZENDESK_KEY = '79513a93-b065-4b83-a30d-9bc0e2dd3ed4';

const canUseDOM = () => {
  return typeof window !== "undefined" && window.document && window.document.createElement;
};

export const ZendeskAPI = (...args) => {
  if (canUseDOM() && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn("Zendesk is not initialized yet");
  }
};

const Zendesk = ({ zendeskKey=ZENDESK_KEY, defer, onLoaded, ...otherProps }) => {
  const [update, setUpdate] = useState(false);
  // Function to handle script loading
  const onScriptLoaded = () => {
    if (typeof onLoaded === "function") {
      onLoaded();
    }

    if (window.zE) {
      window.zE("messenger", "hide");
      window.zESettings = {
        ...otherProps,
        onReady: () => {
          const token = LocalStorage.getItem(LocalStorage.DEFINE_KEY.JWT_TOKEN);
          if (token) {
            window.zE("messenger", "loginUser", function (callback) {
              callback(token);
            });
          } else {
            console.warn("JWT token not found in local storage.");
          }
        }
      };
    }
  };

  // Insert Zendesk script dynamically
  const insertScript = (zendeskKey, defer) => {

    if (!document.getElementById("ze-snippet")) {
      const script = document.createElement("script");
      if (defer) {
        script.defer = true;
      } else {
        script.async = true;
      }
      script.id = "ze-snippet";
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
      script.addEventListener("load", onScriptLoaded);
      document.body.appendChild(script);
      setUpdate(!update);
    } else {
      // If script already exists, call the loaded function immediately
      onScriptLoaded();
    }
  };

  useEffect(() => {
    if (canUseDOM() && !window.zE) {
      insertScript(zendeskKey, defer);
      window.zESettings = otherProps;
    }
  }, [zendeskKey, defer, otherProps]); // Effect dependencies

  useEffect(() => {
    if (canUseDOM() && window.zE) {
      window.zE("messenger", "loginUser", function (callback) {
        callback(LocalStorage.getItem(LocalStorage.DEFINE_KEY.JWT_TOKEN));
        window.zE("messenger", "hide");
      });
    }
  }, [zendeskKey, update]); // Dependency on zendeskKey

  return null;
};

Zendesk.propTypes = {
  zendeskKey: PropTypes.string.isRequired,
  defer: PropTypes.bool,
  onLoaded: PropTypes.func,
};

export default memo(Zendesk);
