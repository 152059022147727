import React, { Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { Userpilot } from 'userpilot'
import { ROUTER_NAME } from './routers'
import {
    AddPaymentMethod,
    Analytics,
    ConnectStripe,
    CreateProfile,
    Dashboard,
    EditPaymentMethod,
    ForgotPassword,
    HomePage,
    Login,
    NotFound,
    Notifications,
    Order,
    OrderDetail,
    Payment,
    Privacy,
    Quotes,
    Settings,
    SignUp,
    Subscription,
    Terms,
    VendorNotesNotifications
} from '../pages'
import SignUpStepTwo from '../pages/sign-up/signUpStepTwo/SignUpStepTwo.page'

import LocalStorage from '../defined/localStorage'
import LayoutAppPrivate from '../layout/private'
import LayoutAppPublic from '../layout/public'
import LayoutAppStripped from '../layout/stripped'
import EmailVerified from '../pages/email-verified/EmailVerified'
import ResetPassword from '../pages/reset-password'
import Utils from '../utils'
import CreateOrder from '../mobile/pages/CreateOrder'
import Vendors from '../mobile/pages/Vendors'
import PaymentMethod from '../mobile/pages/PaymentMethod'
import UserSettings from '../mobile/pages/Settings'
import WhiteLabel from '../pages/WhiteLabel'
import '../mobile/style.css'
import { CircularProgress, styled } from '@mui/material'
import { manifetConfiguration } from './configuration'

Userpilot.initialize('NX-682cade0');

const LoadingContainer = styled('div')(({}) => ({
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'height': '100vh'
}))

//User Wrappers
const WelcomeWrapper = React.lazy(
    async () => import('../mobile/pages/Welcome')
)
const SigninWrapper = React.lazy(
    async () => import('../mobile/pages/Auth/Signin')
)
const SignupWrapper = React.lazy(
    async () => import('../mobile/pages/Auth/Signup')
)
const ForgotPasswordWrapper = React.lazy(
    async () => import('../mobile/pages/Auth/ForgotPassword')
)
const ResetPasswordWrapper = React.lazy(
    async () => import('../mobile/pages/Auth/ResetPassword')
)
const ProfileWrapper = React.lazy(
    async () => import('../mobile/pages/Auth/Profile')
)
const ProfileUpdate = React.lazy(
    async () => import('../mobile/modules/UserProfile')
)
const ChangePass = React.lazy(
    async () => import('../mobile/pages/ChangePassword')
)
const TermsAndConditionsWrapper = React.lazy(
    async () => import('../mobile/pages/TermsAndConditions')
)
const PrivacyPolicyWrapper = React.lazy(
    async () => import('../mobile/pages/PrivacyPolicy')
)
const QuotesWrapper = React.lazy(
    async () => import('../mobile/pages/Quotes')
)
const QuotesDetailWrapper = React.lazy(
    async () => import('../mobile/pages/Quotes/QuotesDetail')
)
const QuotesOrderWrapper = React.lazy(
    async () => import('../mobile/pages/Quotes/QuotesOrder')
)
const SettingsWrapper = React.lazy(
    async () => import('../mobile/pages/Settings')
)
const OrderData = React.lazy(
    async () => import('../mobile/pages/OrderData')
)
const Message = React.lazy(
    async () => import('../mobile/pages/Messages')
)
const OrderDetails = React.lazy(
    async () => import('../mobile/pages/OrderDetails')
)
const OrderQuoteDetails = React.lazy(
    async () => import('../mobile/pages/OrderQuoteDetails')
)
const MobileNotifications = React.lazy(
    async () => import('../mobile/pages/Notifications')
)
const DeleteAccount = React.lazy(
    async () => import('../mobile/pages/DeleteAccount')
)
const AddNewCard = React.lazy(
    async () => import('../mobile/pages/AddNewCard')
)
const AddNewBusinessCard = React.lazy(
    async () => import('../mobile/pages/AddNewBusinessCard')
)
const NotesWrapper = React.lazy(
    async () => import('../pages/notes')
)
const NotesDetailWrapper = React.lazy(
    async () => import('../pages/notes-detail')
)
const ChildAccountsWrapper = React.lazy(
    async () => import('../pages/child-accounts')
)
const ChildAccountsDetailWrapper = React.lazy(
    async () => import('../pages/child-accounts-detail')
)
const VerifyChildAccountsWrapper = React.lazy(
    async () => import('../pages/child-accounts/verify-account')
)
const UserNotification = React.lazy(
    async () => import('../mobile/pages/UserNotifications')
)
const SignupWhiteLabelPartnerWrapper = React.lazy(
    async () => import('../pages/sign-up/whiteLabelPartner')
)
const TeamWrapper = React.lazy(
    async () => import('../pages/team')
)
const TeamDetailWrapper = React.lazy(
    async () => import('../pages/team-detail')
)

const LAYOUT_TYPE = {
    dashboard: 1,
    menu: 2,
    none: 3
}

function PrivateRouteMobileDevice ({ children }) {
    const accessToken = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN)
    const userRole = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ROLE)

    if (!accessToken) {
        const redirectPath = userRole === 'user' ? Utils.EndPoint.userMobileEndpoints.signin : Utils.EndPoint.dealerTechnicianEndpoints.signin
        return <Navigate to={redirectPath}/>
    }

    return children
}

const userRouter = [
    {
        path: Utils.EndPoint.userMobileEndpoints.landing,
        element: <WelcomeWrapper dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.welcome,
        element: <WelcomeWrapper dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.signin,
        element: <SigninWrapper dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.signup,
        element: <SignupWrapper dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.forgot_password,
        element: <ForgotPasswordWrapper dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.reset_password,
        element: <ResetPasswordWrapper dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.changePassword,
        element: <ChangePass dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.notifications,
        element: <MobileNotifications dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.profile,
        element: <ProfileWrapper dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.updateProfile,
        element: <ProfileUpdate dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.delete_account,
        element: <DeleteAccount dt={false}/>,
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.terms,
        element: (
            <TermsAndConditionsWrapper dt={false}/>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.privacy,
        element: (
            <PrivacyPolicyWrapper dt={false}/>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.quotes,
        element: (
            <PrivateRouteMobileDevice>
                <QuotesWrapper dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.quotes_detail,
        element: (
            <PrivateRouteMobileDevice>
                <QuotesDetailWrapper dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.quotes_order,
        element: (
            <PrivateRouteMobileDevice>
                <QuotesOrderWrapper dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.createOrder,
        element: (
            <PrivateRouteMobileDevice>
                <OrderData dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.addNewOrder,
        element: (
            <PrivateRouteMobileDevice>
                <CreateOrder dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.vendors,
        element: (
            <PrivateRouteMobileDevice>
                <Vendors dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.settings,
        element: (
            <SettingsWrapper dt={false}/>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.paymentMethod,
        element: (
            <PrivateRouteMobileDevice>
                <PaymentMethod dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.settings,
        element: (
            <PrivateRouteMobileDevice>
                <UserSettings dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.message,
        element: (
            <PrivateRouteMobileDevice>
                <Message dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.orderDetails,
        element: (
            <PrivateRouteMobileDevice>
                <OrderDetails dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.editOrder,
        element: (
            <PrivateRouteMobileDevice>
                <CreateOrder dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.orderQuote,
        element: (
            <PrivateRouteMobileDevice>
                <OrderQuoteDetails dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.addNewCard,
        element: (
            <PrivateRouteMobileDevice>
                <AddNewCard dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.editCard,
        element: (
            <PrivateRouteMobileDevice>
                <AddNewCard dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.addNewBusinessCard,
        element: (
            <PrivateRouteMobileDevice>
                <AddNewBusinessCard dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.editBusinessCard,
        element: (
            <PrivateRouteMobileDevice>
                <AddNewBusinessCard dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    },
    {
        path: Utils.EndPoint.userMobileEndpoints.userNotification,
        element: (
            <PrivateRouteMobileDevice>
                <UserNotification dt={false}/>
            </PrivateRouteMobileDevice>
        ),
        isUserRoute: true
    }
]

const deliveryTechnicianRouter = [
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.landing,
        element: <WelcomeWrapper dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.welcome,
        element: <WelcomeWrapper dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.signin,
        element: <SigninWrapper dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.signup,
        element: <SignupWrapper dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.forgot_password,
        element: <ForgotPasswordWrapper dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.reset_password,
        element: <ResetPasswordWrapper dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.changePassword,
        element: <ChangePass dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.notifications,
        element: <MobileNotifications dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.profile,
        element: <ProfileWrapper dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.updateProfile,
        element: <ProfileUpdate dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.delete_account,
        element: <DeleteAccount dt={true}/>,
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.terms,
        element: (
            <TermsAndConditionsWrapper dt={true}/>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.privacy,
        element: (
            <PrivacyPolicyWrapper dt={true}/>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.quotes,
        element: (
            <PrivateRouteMobileDevice>
                <QuotesWrapper dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.quotes_detail,
        element: (
            <PrivateRouteMobileDevice>
                <QuotesDetailWrapper dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.quotes_order,
        element: (
            <PrivateRouteMobileDevice>
                <QuotesOrderWrapper dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.createOrder,
        element: (
            <PrivateRouteMobileDevice>
                <OrderData dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.addNewOrder,
        element: (
            <PrivateRouteMobileDevice>
                <CreateOrder dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.vendors,
        element: (
            <PrivateRouteMobileDevice>
                <Vendors dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.settings,
        element: (
            <SettingsWrapper dt={true}/>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.settings,
        element: (
            <PrivateRouteMobileDevice>
                <UserSettings dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.message,
        element: (
            <PrivateRouteMobileDevice>
                <Message dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.orderDetails,
        element: (
            <PrivateRouteMobileDevice>
                <OrderDetails dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.editOrder,
        element: (
            <PrivateRouteMobileDevice>
                <CreateOrder dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.orderQuote,
        element: (
            <PrivateRouteMobileDevice>
                <OrderQuoteDetails dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.addNewCard,
        element: (
            <PrivateRouteMobileDevice>
                <AddNewCard dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.editCard,
        element: (
            <PrivateRouteMobileDevice>
                <AddNewCard dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.addNewBusinessCard,
        element: (
            <PrivateRouteMobileDevice>
                <AddNewBusinessCard dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.editBusinessCard,
        element: (
            <PrivateRouteMobileDevice>
                <AddNewBusinessCard dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    },
    {
        path: Utils.EndPoint.dealerTechnicianEndpoints.userNotification,
        element: (
            <PrivateRouteMobileDevice>
                <UserNotification dt={true}/>
            </PrivateRouteMobileDevice>
        ),
        isDTRoute: true
    }
]

const router = [
    {
        path: '/',
        component: Login,
        isNotPrivate: true
    },
    {
        path: ROUTER_NAME.createProfile,
        component: CreateProfile
    },
    {
        path: ROUTER_NAME.notification,
        component: Notifications
    },
    {
        path: ROUTER_NAME.vendorNotesNotification,
        component: VendorNotesNotifications
    },
    {
        path: ROUTER_NAME.privacy,
        component: Privacy,
        isNotPrivate: true
    },
    {
        path: ROUTER_NAME.privacyPrivate,
        component: Privacy,
        isNotPrivate: false
    },
    {
        path: ROUTER_NAME.term,
        component: Terms,
        isNotPrivate: true
    },
    {
        path: ROUTER_NAME.termsPrivate,
        component: Terms,
        isNotPrivate: false
    },
    {
        path: ROUTER_NAME.signUp,
        component: SignUp,
        isNotPrivate: true
    },
    {
        path: ROUTER_NAME.signUpStepTwo,
        component: SignUpStepTwo,
        isNotPrivate: true
    },
    {
        path: ROUTER_NAME.signUpWhiteLabelPartner,
        component: SignupWhiteLabelPartnerWrapper,
        isNotPrivate: true
    },
    {
        path: ROUTER_NAME.emailVerified,
        component: EmailVerified,
        isStripped: true
    },
    {
        path: ROUTER_NAME.login,
        component: Login,
        isLogin: true,
        layoutType: LAYOUT_TYPE.none
    },
    {
        path: ROUTER_NAME.forgotPassword,
        isNotPrivate: true,
        component: ForgotPassword
    },
    {
        path: ROUTER_NAME.resetPassword,
        isNotPrivate: true,
        component: ResetPassword
    },
    {
        path: ROUTER_NAME.home,
        component: HomePage
    },
    {
        path: ROUTER_NAME.dashboard,
        component: Dashboard
    },
    {
        path: ROUTER_NAME.settings,
        component: Settings
    },
    {
        path: ROUTER_NAME.subscription,
        component: Subscription,
        isAdminOnly: true
    },
    {
        path: ROUTER_NAME.connectStripe,
        component: ConnectStripe,
        isAdminOnly: true
    },
    {
        path: ROUTER_NAME.connectStripe+'?already_redirected=true',
        component: ConnectStripe,
        isAdminOnly: true
    },
    {
        path: ROUTER_NAME.payment,
        component: Payment,
        isAdminOnly: true
    },
    {
        path: ROUTER_NAME.addPaymentMethod,
        component: AddPaymentMethod,
        isAdminOnly: true
    },
    {
        path: ROUTER_NAME.editPaymentMethod,
        component: EditPaymentMethod,
        isAdminOnly: true
    },
    {
        path: ROUTER_NAME.order,
        component: Order
    },
    {
        path: `${ROUTER_NAME.orderDetail}/:id`,
        component: OrderDetail
    },
    {
        path: ROUTER_NAME.quotes,
        component: Quotes
    },
    {
        path: ROUTER_NAME.analytics,
        component: Analytics
    },
    {
        path: ROUTER_NAME.team,
        component: TeamWrapper,
        isAdminOnly: true
    },
    {
        path: `${ROUTER_NAME.teamProfile}/:id`,
        component: TeamDetailWrapper,
        isAdminOnly: true
    },
    {
        path: ROUTER_NAME.whiteLabel,
        component: WhiteLabel,
        isAdminOnly: true
    },
    {
        path: '*',
        component: NotFound,
        isNotPrivate: true
    },
    {
        path: ROUTER_NAME.notes,
        component: NotesWrapper
    },
    {
        path: `${ROUTER_NAME.notesDetail}/:id`,
        component: NotesDetailWrapper
    },
    {
        path: ROUTER_NAME.childAccounts,
        component: ChildAccountsWrapper
    },
    {
        path: `${ROUTER_NAME.childAccounts}/:id`,
        component: ChildAccountsDetailWrapper
    },
    {
        path: ROUTER_NAME.verifyChildAccounts,
        component: VerifyChildAccountsWrapper,
        isNotPrivate: true
    },
    ...userRouter,
    ...deliveryTechnicianRouter
]

const PublicRoute = (props) => {
    const Component = props.component
    const auth = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN)
    if (auth && !props.isNotLayout) {
        return <Navigate to={ROUTER_NAME.dashboard} replace={true}/>
    }
    return Component ? (
        props.isNotLayout ? (
            <Component/>
        ) : (
            <LayoutAppPublic isLandingPage={props.isLandingPage}>
                <Component/>
            </LayoutAppPublic>
        )
    ) : (
        <Outlet/>
    )
}

const PrivateRoute = (props) => {
    const Component = props.component
    const auth = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN)
    const authData = useSelector((state) => state?.auth?.data)
    const navigate = useNavigate()
    useEffect(() => {
        if (authData?.user && !authData?.user?.profile?.dealer) {
            navigate(Utils.EndPoint.userMobileEndpoints.createOrder)
        }
    }, [])
    if (!auth) {
        return <Navigate to={ROUTER_NAME.login} replace={true}/>
    }
    return Component ? (
        props.isNotLayout ? (
            <Component/>
        ) : props.isLayoutPublic ? (
            <LayoutAppPublic>
                <Component/>
            </LayoutAppPublic>
        ) : (
            <LayoutAppPrivate>
                <Component/>
            </LayoutAppPrivate>
        )
    ) : (
        <Outlet/>
    )
}

const StrippedRoute = (props) => {
    const Component = props.component
    return (
        <LayoutAppStripped>
            <Component/>
        </LayoutAppStripped>
    )
}

const RouteNavigation = () => {
    const auth = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN)
    console.log('Userpilot auth', auth)
    const user = useSelector((state) => state.User?.userInfo)
    const brand = useSelector((state) => state?.User?.branding)
    const authData = useSelector((state) => state?.auth?.data)
    if (!auth) {
        console.log('Userpilot anonymous')
        Userpilot.anonymous()
    }

    useEffect(() => {manifetConfiguration(brand)}, [brand])

    const dealerRouterFiltered = router.filter((route) => {
        return !route?.isUserRoute || !route?.isDTRoute
    })

    const routerFiltered = dealerRouterFiltered.filter((route) => {
        return !route?.isAdminOnly && user?.profile?.team_member
    })

    const routes = user?.profile?.team_member ? routerFiltered : router

    useEffect(() => {
        const isUser = authData?.user?.role === 'user' || authData?.user?.profile?.user?.role === 'user'
        const isDT = authData?.user?.role === 'DT' || authData?.user?.profile?.user?.role === 'DT'

        if (window.location.pathname.startsWith('/customer') || window.location.pathname.startsWith('/dealer-technician')) {
            if (isUser && !window.location.pathname.startsWith('/customer')) {
                window.location.href = window.location.pathname.replace('/dealer-technician', '/customer')
            }
            if (isDT && !window.location.pathname.startsWith('/dealer-technician')) {
                window.location.href = window.location.pathname.replace('/customer', '/dealer-technician')
            }
        }
    }, [window.location.pathname])

    return (
        <Router>
            <Suspense fallback={<LoadingContainer> <CircularProgress/></LoadingContainer>}>
                <Routes>
                    {routes.map((route, index) => {
                        return (
                            <React.Fragment key={index}>
                                {
                                    route.isUserRoute === true || route.isDTRoute === true
                                        ? (
                                            <Route
                                                path={route.path}
                                                element={route.element}
                                            />
                                        ) : (
                                            <Route
                                                path={route.path}
                                                element={
                                                    route.isNotPrivate || route.isLandingPage || route.isLogin ? (
                                                        <PublicRoute {...route} />
                                                    ) : route.isStripped ? (
                                                        <StrippedRoute {...route} />
                                                    ) : (
                                                        <PrivateRoute {...route} />
                                                    )
                                                }
                                            />
                                        )
                                }
                            </React.Fragment>
                        )
                    })}
                </Routes>
            </Suspense>
        </Router>
    )
}
export default RouteNavigation
